<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <UserMenu></UserMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-2">
        <v-row class="rowBg pa-5">
          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">
                    {{ totalPurchases.length }}
                  </h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn block class="buttonStyle mt-5" @click="getRecentPurchases">All Orders</v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">{{ deliveredPurchases.length }}</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn block class="buttonStyle mt-5" @click="fetchDeliveredOrders">Delivered Orders</v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">{{ canceledPurchases.length }}</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn block class="buttonStyle mt-5" @click="fetchCanceledOrders">Canceled Orders</v-btn>
          </v-col>
        </v-row>

        <!--Recent ManufacturersProduct Products-->
        <v-row class="mt-4 mb-5">
          <v-col cols="12">
            <h5>Recent Orders</h5>
            <v-row
              wrap
              class="pa-5"
              style="background-color: #f2f2f2"
              v-if="recentPurchases.length >= 1"
            >
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                         Order Id
                        </th>
                        <th class="text-left">
                          Tracking Number
                        </th>
                        <th class="text-left">
                          Price
                        </th>
                        <th class="text-left">
                          Status
                        </th>
                        <th class="text-left">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in recentPurchases"
                        :key="item.orderId"
                      >
                        <td>{{ item.orderId }}</td>
                        <td>{{ item.trackingNumber }}</td>
                        <td>
                          <b
                            >{{ systemSetting.systemCurrency
                            }}{{ item.subTotal }}</b
                          >
                        </td>
                        <td>
                          
                          <span
                            v-if="item.status == 'created'"
                            style="color:Red;"
                            >Awaiting Shipping</span
                          >
                          <span
                            v-else-if="item.status == 'approved'"
                            style="color:blue;"
                            >Awaiting Delivery</span
                          >
                          <!-- <span
                            v-else-if="item.status == 'delivered'"
                            style="color:green;"
                            >Order delivered</span
                          > -->
                          <span
                            v-else-if="item.status == 'completed'"
                            style="color:green;"
                            >Order delivered</span
                          >
                          <span v-else style="color:red;"
                            >Pending</span
                          >
                        </td>
                        <td>
                          <v-btn rounded circle v-on:click="viewProduct(item)">view products</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-dialog v-model="orderInfoDialog" width="600">
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Order Details
                      <v-spacer></v-spacer>
                      <v-btn icon @click="orderInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-layout wrap>
                        <v-col cols="12">
                           <p><b>Order Id:</b> {{orderItem.orderId}}</p>
                           <p><b>Tracking Number:</b> {{orderItem.trackingNumber}}</p>
                           <p><b>Order Date:</b> {{orderItem.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a")}}</p>
                        </v-col>
                      </v-layout>
                      <v-layout wrap>
                        <v-col cols="12">
                          <h6>Ordered Items</h6>
                          <v-divider></v-divider>
                           <v-row  v-for="(order, index) in orderItem.OrderItems"
                              :key="index" style="border-bottom:1px solid black; margin-bottom:10px;">
                            <v-col cols="3">
                              <v-img
                                class="ma-2"
                                width="70"
                                height="70"
                                :src="order.Product.productImagePath"
                                contain
                              />
                            </v-col>
                            <v-col cols="6">
                              <p>
                                <b>Product Name:</b> {{order.Product.name}}<br>
                                <b>Product Price:</b> {{ systemSetting.systemCurrency}}{{order.Product.price}}<br>
                                <b>Quantity:</b> {{order.qty}}<br>
                                <b>Total Cost:</b> {{ systemSetting.systemCurrency}}{{order.total}}<br>
                              </p>
                              
                            </v-col>
                            <v-col cols="3">
                                <v-btn v-if="orderItem.status == 'delivered'" x-small rounded circle color="success" @click="toggleReview(order.Product.productId)">Review Product</v-btn>
                                <v-btn v-else-if="orderItem.status == 'approved'" x-small rounded circle color="success" @click="approveOrderStatus(orderItem.orderId)">Approve Order</v-btn>
                               
                            </v-col>
                           </v-row>
                           <h5>Total Paid: {{ systemSetting.systemCurrency
                            }}{{orderItem.subTotal}}</h5>
                            <p class="text-success">{{successMessage}}</p>
                            <p class="text-danger">{{errorMessage}}</p>
                            <v-divider></v-divider>
                            <v-row v-if="isReviewed">
                                      <p>Please drop a review for the product </p>
                                      <p>
                                        <v-textarea
                                                label="drop a comment"
                                                v-model="productReviewData.review"
                                                outlined
                                                rows="2"
                                                row-height="15"
                                                dense>
                                        </v-textarea>
                                        <v-rating
                                              v-model="productReviewData.rating"
                                              :value="productReviewData.rating"
                                              small
                                              color="yellow darken-3"
                                              background-color="grey darken-1"
                                              half-increments
                                              hover
                                          ></v-rating>
                                      </p>
                                      <v-row>
                                              <v-progress-linear
                                                color="#7CBF46"
                                                v-show="loader"
                                                :indeterminate="true"
                                              ></v-progress-linear>
                                      </v-row>
                                      <p>
                                         <v-btn small rounded circle v-on:click="submitReview" color="primary">Submit</v-btn>
                                      </p>

                              </v-row>
                        </v-col>
                      </v-layout>
                    </v-card-text>
                  </v-card>
            </v-dialog>

            <v-row wrap class="pa-10" v-if="recentPurchases.length <= 0">
              <v-col cols="12" align="center" justify="center">
                <p>
                  <v-img
                    alt="System Logo"
                    class="shrink mr-2"
                    contain
                    :src="'img/emptyProductList.gif'"
                    transition="scale-transition"
                    width="350"
                  />
                </p>
                <p class="text-h5">
                  No Order Available
                </p>
              </v-col>
            </v-row>

            <!---- Edit Member Profile---->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMenu from "../others/UserMenu.vue";
import { Country } from "country-state-city";

export default {
  name: "UserDashboard",

  components: {
    UserMenu,
  },
  created() {
    this.user_id = localStorage.getItem("userId");
    this.getCountry();
    this.getRecentPurchases();
  },
  data: () => ({
    orderInfoDialog: false,
    isReviewed: false,
    loader: false,
    successMessage: "",
    errorMessage: "",
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "My Account",
        disabled: false,
        href: "/",
      },
      {
        text: "Overview",
        disabled: true,
        href: "/#/overview/user/dashboard/en",
      },
    ],
    country: [],
    totalPurchases:[],
    recentPurchases: [],
    deliveredPurchases: [],
    canceledPurchases: [],
    systemSetting: {
      systemCurrency: "GH₵",
    },
    user_id: "",
    orderItem:{
      LogisticsCompany:{
        name:"",
        contactPerson:"",
        email:"",
      }
    },
    productReviewData:{
      productId:"",
      userId:null,
      review:"",
      rating:null
    }
  }),
  methods: {
    toggleReview(productId) {
      this.productReviewData.productId = productId;
      this.isReviewed = !this.isReviewed;
      this.productReviewData.userId = localStorage.getItem("userId");
    },

    getCountry() {
      this.country = Country.getAllCountries();
    },

    getRecentPurchases() {
      this.deliveredPurchases=[];
      this.canceledPurchases=[];
      this.$http
        .get(`${process.env.VUE_APP_URL}order/` + this.user_id)
          .then((response) => {
            this.recentPurchases = response.data.data.slice(0,10);
            this.totalPurchases = response.data.data;
            response.data.data.forEach(element => {
              if(element.status == 'completed')
              {
                this.deliveredPurchases.push(element);
              }

              if(element.status == 'canceled')
              {
                this.canceledPurchases.push(element);
              }
          });
          
        });
    },

    fetchDeliveredOrders() {
      this.recentPurchases = [];
        this.$http
        .get(`${process.env.VUE_APP_URL}order/` + this.user_id)
        .then((response) => {
          response.data.data.forEach(element => {
            if(element.status == 'delivered')
            {
               this.recentPurchases.push(element);
            }
          });
        });
    },

    fetchCanceledOrders() {
      this.recentPurchases = [];
        this.$http
        .get(`${process.env.VUE_APP_URL}order/` + this.user_id)
        .then((response) => {
          response.data.data.forEach(element => {
            if(element.status == 'canceled')
            {
               this.recentPurchases.push(element);
            }
          });
        });
    },

    viewProduct(item) {
      this.orderItem = item;
      this.orderInfoDialog = true;
    },

    submitReview() {
          this.loader = true;
          this.successMessage = "";
          this.errorMessage = "";

          this.$http
            .post(`${process.env.VUE_APP_URL}product/review`, this.productReviewData
            )
            .then(() => {
                this.loader = false;
                this.isReviewed = false;
                this.successMessage = "Review submitted succsessfully. ";
            })
            .catch((err) => {
              this.loader = false;
              console.log(err.response)
              this.errorMessage = err.response.data.data[0];
            });
    },

    approveOrderStatus(orderId){
        this.loader =true;
        this.$http
        .put(`${process.env.VUE_APP_URL}order/complete/` + orderId)
        .then(() => {
          this.loader =false;
          this.reloadPage();
        });
    },
    reloadPage(){
      location.reload();
    }
  },
};
</script>

<style scoped>
.priceStyle2 {
  width: 80px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iconPadding {
  padding: 50px !important;
}

.iconStyle {
  color: yellowgreen;
  font-size: 45px;
}
</style>
